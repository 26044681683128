import { TestStore } from "storeContainer";
import AccountsStore from "./AccountsStore";
import { AclStore } from "./AclStore";
import { AclStore2 } from "./AclStore2";
import { ApprovalStore } from "./ApprovalStore";
import AutoDepositRulesStore from "./AutoDepositRulesStore";
import BudgetManagementStore from "./BudgetManagementStore";
import { CompanyGroupsStore } from "./CompanyGroupsStore";
import { CompanyStore } from "./CompanyStore";
import { ContextStore } from "./ContextStore";
import { FeatureAccessRequestStore } from "./FeatureAccessRequestStore";
import { GLAccountsStore } from "./GLAccountsStore";
import { IntegrationsStore } from "./IntegrationsStore";
import { OrganizationStore } from "./OrganizationStore";
import { OrganizationUsersStore } from "./OrganizationUsersStore";
import { SessionStore } from "./SessionStore";
import { StoreBase } from "./StoreBase";
import { UiStore } from "./UiStore";
import { UndepositedChecksStore } from "./UndepositedChecksStore";
import { UserGroupsStore } from "./UserGroupsStore";
import { UserSettingsStore } from "./UserSettingsStore";
import { VBillBatchDetailsStore } from "./VBillBatchDetailsStore";
import { VBillBatchLedgerStore } from "./VBillBatchLedgerStore";
import { VBillCodingLedgerStore } from "./VBillCodingLedgerStore";
import { VBillPayLedgerStore } from "./VBillPayLedgerStore";
import { VBillPaymentsLedgerStore } from "./VBillPaymentsLedgerStore";
import { VBillSessionStore } from "./VBillSessionStore";
import { VBillStore } from "./VBillStore";
import { VChecksStore } from "./VChecksStore";
import { VendorsStore } from "./VendorsStore";

const ctorStoreContainer = () => ({
  AccountsStore: new AccountsStore(),
  ContextStore: new ContextStore(),
  GLAccounts: new GLAccountsStore(),
  IntegrationsStore: new IntegrationsStore(),
  OrganizationUsersStore: new OrganizationUsersStore(),
  VendorsStore: new VendorsStore(),
  UserGroupsStore: new UserGroupsStore(),
  SessionStore: new SessionStore(),
  AclStore: new AclStore(),
  AclStore2: new AclStore2(),
  test: new TestStore(),
  OrganizationStore: new OrganizationStore(),
  CompanyStore: new CompanyStore(),
  UiStore: new UiStore(),
  VChecksStore: new VChecksStore(),
  UndepositedChecksStore: new UndepositedChecksStore(),
  VBillStore: new VBillStore(),
  VBillSessionStore: new VBillSessionStore(),
  VBillCodingLedgerStore: new VBillCodingLedgerStore(),
  VBillBatchDetailsStore: new VBillBatchDetailsStore(),
  VBillBatchLedgerStore: new VBillBatchLedgerStore(),
  VBillPayLedgerStore: new VBillPayLedgerStore(),
  VBillPaymentsLedgerStore: new VBillPaymentsLedgerStore(),
  AutoDepositRulesStore: new AutoDepositRulesStore(),
  BudgetManagementStore: new BudgetManagementStore(),
  ApprovalStore: new ApprovalStore(),
  UserSettingsStore: new UserSettingsStore(),
  FeatureAccessRequestStore: new FeatureAccessRequestStore(),
  CompanyGroupsStore: new CompanyGroupsStore(),
});

export type IStoreContainer = ReturnType<typeof ctorStoreContainer>;
export const initStoreContainer = () => {
  const storeContainer = ctorStoreContainer();
  for (let storeName of Object.keys(storeContainer) as (keyof IStoreContainer)[]) {
    const store = storeContainer[storeName];
    if (store instanceof StoreBase) {
      store.init(storeContainer);
    }
  }
  return storeContainer;
};
