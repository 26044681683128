import { graphqlVBillClient } from "common/graphqlClient";
import { __IS_ADMIN_VBILL__ } from "components/pages/VBill/utils";
import { getSdk, IVBillPermission, IVBillVBillSessionQuery } from "generated/sdk.vbill";
import { computed, makeObservable } from "mobx";
import { createObservableContainer } from "storeContainer";

const { VBillSession } = getSdk(graphqlVBillClient);

export class VBillSessionStore {
  defaultCHUserPermissions = Object.values(IVBillPermission);
  // defaultCHUserPermissions = [IVBillPermission.MapOcr];

  constructor() {
    makeObservable(this, {
      hasMapCompanyPermission: computed,
      hasMapDataPermission: computed,
      hasMapOcrPermission: computed,
      hasStartApprovalPermission: computed,
    });
  }

  session = createObservableContainer<NonNullable<IVBillVBillSessionQuery["session"]>>();

  async loadSession() {
    this.session.cachedLoad(async () => (await VBillSession()).session, []);
  }

  get sessionPermissions() {
    return __IS_ADMIN_VBILL__ ? this.session.data?.hasPermissions ?? [] : this.defaultCHUserPermissions;
  }

  get hasMapCompanyPermission() {
    return this.sessionPermissions.includes(IVBillPermission.MapCompany);
  }

  get hasMapDataPermission() {
    return this.sessionPermissions.includes(IVBillPermission.MapData);
  }

  get hasMapOcrPermission() {
    return this.sessionPermissions.includes(IVBillPermission.MapOcr);
  }

  get hasStartApprovalPermission() {
    return this.sessionPermissions.includes(IVBillPermission.StartApproval);
  }
}
