import { LedgerFilterInputType, getFiltersConfig } from "components/Filter/types";
import {
  CheckActionEnumType,
  CheckContextEnumType,
  CheckFiltersType,
  CheckQuickFiltersEnumType,
  GetSharedFiltersQueryQuery,
  Pagination,
  SetSenderPreferrencesMutationVariables,
  SortEnumType,
  VCheckCheckQueryQuery,
  VCheckChecksQuery,
  VCheckChecksStatsQuery,
  VCheckRelatedTransactionsQuery,
  getSdk,
} from "../generated/sdk";
import { DataContainerObsevable, createContainer, createObservableContainer } from "../storeContainer";

import { isNil } from "lodash";
import { runInAction } from "mobx";
import { graphqlClient } from "../common/graphqlClient";
import { definitely } from "../generated/utils";
import { StoreBase } from "./StoreBase";

const {
  VCheckChecks,
  CheckAction,
  VCheckCheckQuery,
  VCheckRelatedTransactions,
  UpdateCheck,
  CreateCheckNote,
  VCheckChecksStats,
  GetSharedFiltersQuery,
  SaveSharedFiltersMutation,
  SetSenderPreferrences,
  DeleteOrganizationVendorFlag,
} = getSdk(graphqlClient);

const vCheckPostProcess = (vChecks: VCheckChecksQuery["VCheckChecks"], pagination: Pagination | undefined) => {
  if (vChecks)
    vChecks.checks =
      vChecks.checks?.map((check, index) => {
        return { ...check, idx: `${pagination?.page}-${index}` };
      }) || [];
  return vChecks;
};

const fetchChecks = async (
  organization_id: string | undefined,
  filters: CheckFiltersType | undefined,
  pagination: Pagination | undefined,
) => {
  const result = await VCheckChecks({ organization_id, filters, pagination });

  return definitely(vCheckPostProcess(result?.VCheckChecks, pagination));
};

const fetchCheck = async (id: string, bank_account_id?: string) => {
  return definitely((await VCheckCheckQuery({ id, bank_account_id }))?.VCheckCheckQuery);
};

const fetchCheckRelatedTransactions = async (id: string, bank_account_id?: string) => {
  return definitely((await VCheckRelatedTransactions({ id, bank_account_id }))?.VCheckCheckQuery);
};

const fetchCheckStats = async (
  organization_id: string | undefined,
  filters: CheckFiltersType,
  quick_filters: CheckQuickFiltersEnumType[],
) => {
  const result = await VCheckChecksStats({ organization_id, filters, quick_filters });

  return definitely(result.VCheckChecks);
};

export class VChecksStore extends StoreBase {
  checksList = createObservableContainer<NonNullable<VCheckChecksQuery["VCheckChecks"]>>();
  csvData = createObservableContainer<NonNullable<VCheckChecksQuery["VCheckChecks"]>>();
  check = createContainer<NonNullable<VCheckCheckQueryQuery["VCheckCheckQuery"]>>();
  checkRelatedTransactions = createContainer<NonNullable<VCheckRelatedTransactionsQuery["VCheckCheckQuery"]>>();
  checksStats = createObservableContainer<NonNullable<VCheckChecksStatsQuery["VCheckChecks"]>>();
  getPathAndTypeContext() {
    const path = window.location.pathname;
    const typeContext = path.includes("/vchecks/payments")
      ? CheckContextEnumType.Withdrawals
      : path.includes("/vchecks/deposits")
        ? CheckContextEnumType.Deposits
        : CheckContextEnumType.Unvalidated;
    const selectedComapniesIds = this.storeContainer?.UserSettingsStore.companiesSelectedIds;
    return { path, typeContext, selectedComapniesIds };
  }
  sharedFilters = createObservableContainer<GetSharedFiltersQueryQuery>();

  private async genericLoadChecks(
    target: DataContainerObsevable<VCheckChecksQuery["VCheckChecks"]>,
    orgId?: string,
    filters?: CheckFiltersType,
    pagination?: Pagination,
    force: boolean = false,
  ): Promise<DataContainerObsevable<VCheckChecksQuery["VCheckChecks"]>> {
    const { path, typeContext, selectedComapniesIds } = this.getPathAndTypeContext();
    const depositAccountsMatch = path.includes("depositAccounts/ledger");

    const dateFormConfig = getFiltersConfig(path, filters?.quick_filter ?? "all")?.[
      LedgerFilterInputType.AdvancedDateFilter
    ];

    const keyNameInput = dateFormConfig?.keyNameInput ?? "";
    const keyNameContextOptions = dateFormConfig?.keyNameContextOptions ?? "";
    const contextDefaultValue = dateFormConfig?.contextOptions?.find((item) => item.default)?.value ?? "";
    const filtersDateValue = filters?.[keyNameInput as keyof CheckFiltersType];

    const defaultSearchObject = {
      search_input: "",
      additional_search_input: "",
      search_options: [],
      additional_search_options: [],
    };

    const searchFilters = { ...defaultSearchObject, ...filters?.search };
    return target.cachedLoad(
      () =>
        fetchChecks(
          depositAccountsMatch ? undefined : orgId,
          {
            ...filters,
            search: searchFilters,
            [keyNameInput]: filtersDateValue || { [keyNameContextOptions]: contextDefaultValue },
            date_sort: filters?.date_sort ?? SortEnumType.Desc,
            type: typeContext,
            companies: selectedComapniesIds?.length && !depositAccountsMatch ? selectedComapniesIds : undefined,
          },
          pagination,
        ),
      [filters, pagination, orgId, path, selectedComapniesIds],
      { forceUpdate: force },
    );
  }

  async loadChecksStats(orgId?: string, forceUpdate?: boolean) {
    const { path, typeContext, selectedComapniesIds } = this.getPathAndTypeContext();
    const depositAccountsMatch = path.includes("depositAccounts/ledger");
    return await this.checksStats.cachedLoad(
      () =>
        fetchCheckStats(
          orgId,
          !depositAccountsMatch
            ? { type: typeContext, companies: selectedComapniesIds?.length ? selectedComapniesIds : undefined }
            : { type: typeContext },
          typeContext === CheckContextEnumType.Withdrawals
            ? [
                CheckQuickFiltersEnumType.PendingSignature,
                CheckQuickFiltersEnumType.Processing,
                CheckQuickFiltersEnumType.ActionRequired,
                CheckQuickFiltersEnumType.UnresolvedChats,
              ]
            : [
                CheckQuickFiltersEnumType.ReadyToDeposit,
                CheckQuickFiltersEnumType.Processing,
                CheckQuickFiltersEnumType.ActionRequired,
              ],
        ),
      [orgId, path, selectedComapniesIds],
      { forceUpdate },
    );
  }

  async loadChecks(orgId?: string, filters?: CheckFiltersType, pagination?: Pagination, force?: boolean) {
    return this.genericLoadChecks(this.checksList, orgId, filters, pagination, force);
  }

  async loadChecksForCSV(orgId?: string, filters?: CheckFiltersType, pagination?: Pagination, force?: boolean) {
    return this.genericLoadChecks(this.csvData, orgId, filters, pagination, force);
  }

  async loadCheck(id: string, bank_account_id?: string, force?: boolean) {
    return await this.check.cachedLoad(() => fetchCheck(id, bank_account_id), [id, bank_account_id], {
      forceUpdate: force,
    });
  }

  async reloadCheckAndUpdateLedger(id: string, bank_account_id?: string) {
    const checkResp = await this.check.cachedLoad(() => fetchCheck(id, bank_account_id), [id, bank_account_id], {
      forceUpdate: true,
      markAsLoading: false,
    });

    runInAction(() => {
      if (checkResp.data?.ledger_check) {
        const checkIndex = this.checksList.data?.checks?.findIndex((check) => check.id === id);
        if (this.checksList._dataObsevable?.checks && !isNil(checkIndex) && checkIndex !== -1) {
          this.checksList._dataObsevable.checks[checkIndex] = checkResp.data.ledger_check;
        }
      }
    });
  }

  async loadCheckRelatedTransactions(id: string, bank_account_id?: string, force?: boolean) {
    return await this.checkRelatedTransactions.cachedLoad(
      () => fetchCheckRelatedTransactions(id, bank_account_id),
      [id, bank_account_id],
      {
        forceUpdate: force,
      },
    );
  }

  async checkAction(action: CheckActionEnumType, checks: string[], organization_id_arg?: string) {
    const organization_id = organization_id_arg || this.storeContainer?.ContextStore.selectedOrganizationId;
    if (organization_id) {
      return CheckAction({ organization_id, checks, action });
    } else {
      return CheckAction({ checks, action });
    }
  }

  async updateCheck(id: string, pttoo?: string, memo?: string) {
    await UpdateCheck({ id, pttoo, memo });
  }

  async createCheckNote(context: number, check_id: string, note?: string, shared?: boolean, attachments?: any) {
    await CreateCheckNote({ check_id, note, shared, context, attachments });
  }

  async loadSharedFilters(token: string, forceUpdate: boolean) {
    return await this.sharedFilters.cachedLoad(async () => await GetSharedFiltersQuery({ token }), [token], {
      forceUpdate,
    });
  }

  async saveSharedFilters(organization_id: string, context: string, filters: string) {
    return await SaveSharedFiltersMutation({ organization_id, context, filters });
  }

  async setSenderPreferrences(input: SetSenderPreferrencesMutationVariables) {
    return await SetSenderPreferrences(input);
  }

  async deleteOrganizationVendorFlag(check_id: string, organization_id: string) {
    return await DeleteOrganizationVendorFlag({
      check_id,
      organization_id,
    });
  }
}
